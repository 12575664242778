import React, { Fragment, useContext, useEffect, useState } from "react";
import classes from './TableMainResults.module.css'
import { currencyFormatter } from "../../../utilities/Formatters";



const TableMainResults = (props) => {
    
    return(
        <div className = {classes.table_container}> 
        <table className= {classes.main_table}> 
            <thead> 
                <tr> 
                    <td> Benefit </td>
                    <td> Legacy </td>
                    <td> Reformed </td>
                </tr>
            </thead>
            <tbody> 
                <tr> 
                    <td>
                        {props.legacySchemeName + ' pension'}
                    </td>
                    <td> 
                        {currencyFormatter(props.pension.choose_legacy.old)}
                    </td>
                    <td> 
                        {currencyFormatter(props.pension.choose_reformed.old)}
                    </td>
                </tr>
                <tr> 
                    <td>
                    {props.remedySchemeName + ' pension ' + (props.carePaymentLater ? " payable at age 55" : "")}
                    </td>
                    <td> 
                        {props.carePaymentLater ? currencyFormatter(props.pension.choose_legacy.new_later) : currencyFormatter(props.pension.choose_legacy.new)}
                    </td>
                    <td> 
                        {props.carePaymentLater ? currencyFormatter(props.pension.choose_reformed.new_later) : currencyFormatter(props.pension.choose_reformed.new)}
                    </td>
                </tr>
                <tr> 
                    <td>
                        {props.legacySchemeName + ' lump sum'}
                    </td>
                    <td> 
                        {currencyFormatter(props.lump_sum.choose_legacy.old)}
                    </td>
                    <td> 
                        {currencyFormatter(props.lump_sum.choose_reformed.old)}
                    </td>
                </tr>
                <tr> 
                    <td>
                    {props.remedySchemeName + ' lump sum' + (props.carePaymentLater ? " payable at age 55" : "")}
                    </td>
                    <td> 
                        {currencyFormatter(props.lump_sum.choose_legacy.new)}
                    </td>
                    <td> 
                        {currencyFormatter(props.lump_sum.choose_reformed.new)}
                    </td>
                </tr>
            </tbody>

        </table>
        </div>
    )
}

export default TableMainResults