import { createContext } from "react";

export const InputDataContext = createContext({
  inputs: {
    employer: "Police",
    region: "Scotland",
    RAR: null,
    inReceipt: null,
    Practitioner: null,
    hasLeft: null,
    dateLeftScheme: null,
    dateOfBirth: null,
    spa: null,
    dateOfJoining: null,
    MHO: null,
    MaleNurse: null,
    BenefitStatement: null,
    Pension2022: null, 
    Service2022: null,
    salaryCurrent: null,
    salary2015: null,
    retirementAge: null,
    hasPT: null,
    hasSS: null,
    partTimeService: [],
    specialService: [],
    salaryIncrease: 0,
    legacyScheme: null,
    rank: null,
    hasTransferredService: null,
    dateOfTransfer: null,
    transferredServiceLegacyYears: null,
    transferredServiceLegacyDays: null,
    transferredService2015: null,
    hasSchemePays: null,
    schemePaysDebits: [],
    minRetAge: null,
    maxRetAge: null,
  },
  setInputs: () => {},
  valid: {
    RAR: false,
    inReceipt: false,
    Practitioner: false,
    hasLeft: false,
    dateLeftScheme: false,
    dateOfBirth: false,
    dateOfJoining: false,
    MHO: false,
    MaleNurse: true,
    BenefitStatement: true,
    Pension2022: true, 
    Service2022: true,
    salaryCurrent: false,
    salary2015: false,
    retirementAge: false,
    hasPT: false,
    hasSS: false,
    legacyScheme: false,
    rank: false,
    hasTransferredService: false,
    dateOfTransfer: false,
    transferredServiceLegacy: false,
    transferredService2015: false,
    hasSchemePays: false,
  },
  setValid: () => {},
});
