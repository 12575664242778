import React, { useState, useEffect, useRef } from 'react';
import classes from './GenericDropdown.module.css';  // Use the existing CSS module
import PrettyTextInput from './PrettyTextInput';

const GenericDropdown = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [customInput, setCustomInput] = useState('');
  const [selectedOption, setSelectedOption] = useState(`Select ${props.label}`);
  const [isCustomInputSelected, setIsCustomInputSelected] = useState(false);
  const scrollableElementRef = useRef(null);

  useEffect(() => {
    if (props.value && props.value.label) {
      setSelectedOption(props.value.label); 
    } else {
      setSelectedOption(`Select ${props.label}`); // Reset to default if no value is passed
    }
  }, [props.value, props.label]);  // Ensure this effect re-runs when value or label changes

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    // Reset the dropdown when the external reset trigger changes
    if(props.resetTaxYearTrigger) {
      setSelectedOption(`Select ${props.label}`);
    }
  }, [props.resetTaxYearTrigger]); 

  const handleOutsideClick = (event) => {
    if (scrollableElementRef.current && !scrollableElementRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  const handleDropdownClick = () => {
    setExpanded(!expanded);
  };

  const handleOptionClick = (option) => {
    let optionValue = option;
    let optionLabel = option;

    if (typeof option === 'object') {
      optionValue = option.value;
      optionLabel = option.label;
    }

    if (optionValue === `Custom ${props.label}`) {
      setIsCustomInputSelected(true);
      setSelectedOption(optionLabel);
      props.setValue({label: optionLabel, value: ''});
    } else {
      setIsCustomInputSelected(false);
      setSelectedOption(optionLabel);
      props.setValue({label: optionLabel, value: optionValue});
      props.onChange({ label: optionLabel, value: optionValue });
    }
    setExpanded(false);
  };

  const handleCustomInputChange = (event) => {
    const newValue = event.target.value.replace(/[£,]/g, ''); // Strip currency symbols for raw numeric value
    setCustomInput(newValue); // Update internal state as a raw value
    props.setValue({ label: `Custom ${props.label}`, value: newValue });
    props.onChange({ label: `Custom ${props.label}`, value: newValue });
  };


  const main_color = props.go_red ? 'lightcoral' : (selectedOption === `Select ${props.label}` ? 'var(--background-default)' : 'var(--border-selected)')
  const shadow_color = props.go_red ? 'red' : (selectedOption === `Select ${props.label}` ? 'grey' : 'none')


  return (
    <div className={classes.dropdown_wrapper}>
      <div className={classes.dropdown_container} ref={scrollableElementRef}>
        <div
          className={classes["dropdown-el"] + (expanded ? ` ${classes.expanded}` : '')}
          onClick={handleDropdownClick}
          style={{ '--color': main_color, '--shadow-color': shadow_color }}
        >
          {!expanded && <p>{selectedOption}</p>}
          {expanded && (
            <div className={classes.sub_container}>
              {props.allowCustomAmount && (
                <div onClick={() => handleOptionClick(`Custom ${props.label}`)}>
                  <input
                    type="radio"
                    name="dropdown"
                    value={`Custom ${props.label}`}
                    checked={selectedOption === `Custom ${props.label}`}
                    readOnly
                  />
                  <label>Custom {props.label}</label>
                </div>
              )}
              {props.allowNoValue && (
                <div onClick={() => handleOptionClick(`Unknown salary`)}>
                  <input
                    type="radio"
                    name="dropdown"
                    value={`Unknown salary`}
                    checked={selectedOption === `Unknown salary`}
                    readOnly
                  />
                  <label>Unknown salary</label>
                </div>
              )}
              {props.options.map((option, index) => (
                <div key={index} onClick={() => handleOptionClick(option)}>
                  <input
                    type="radio"
                    name="dropdown"
                    value={typeof option === 'object' ? option.value : option}
                    checked={selectedOption === (typeof option === 'object' ? option.label : option)}
                    readOnly
                  />
                  <label>{typeof option === 'object' ? option.label : option}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {isCustomInputSelected && (
        <div className={classes.customInputContainer}>
          <PrettyTextInput
            type="currency"
            value={customInput}
            onChange={handleCustomInputChange}
            className={classes.customInput}
          />
        </div>
      )}
    </div>
  );
};

export default GenericDropdown;
