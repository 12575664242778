export const retageAPI = async (inputs, setInputs, resetRetirementAge) => {
  let address =
    (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "/api/retage";

  const service_days = inputs.transferredServiceLegacyDays === "xxx" ? 0 : (inputs.transferredServiceLegacyDays === '' ? 0 : inputs.transferredServiceLegacyDays)
  const service_years = inputs.transferredServiceLegacyYears === "xxx" ? 0 : (inputs.transferredServiceLegacyYears === '' ? 0 : inputs.transferredServiceLegacyYears)

  const transferred_service_legacy = (inputs.hasTransferredService === "Yes" && new Date(inputs.dateOfTransfer) < new Date("2015-04-01")) ? (service_years + service_days/365) : 0

  const requestOptions = {
    method: "Post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ 
      employer: inputs.employer,
      region: inputs.region,
      dateOfBirth : inputs.dateOfBirth,
      dateJoinedScheme: inputs.dateOfJoining,
      scheme: inputs.legacyScheme,
      hasTransferredService: inputs.hasTransferredService,
      transferDate: inputs.dateOfTransfer,
      transferredInService : transferred_service_legacy,
      transferredInPension : inputs.transferredService2015,
      hasPT : inputs.hasPT,
      partTimes: inputs.partTimeService,
      rankAtRetirement: inputs.rank["value"]
     }),
  };


  try {
    const response = await fetch(address, requestOptions);
    const json = await response.json();
    if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
    }
    setInputs({
        ...inputs,
        retirementAge: resetRetirementAge,
        minRetAge: json["min_age"], // Adjusted to receive min_age
        maxRetAge: json["max_age"]  // Adjusted to receive max_age
    });
} catch (error) {
    console.error('Error fetching retirement age data:', error);
    setInputs((prev) => ({...prev, minRetAge: 'error', maxRetAge: 'error'}));
}
};
