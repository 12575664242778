import React from "react";
import NavBar from "./NavBar";
import gadLogo from "../../assets/images/gadLogo.png";
import sppaLogo from "../../assets/images/sppaLogo.png";
import classes from "./Header.module.css";

const Header = () => {
  
  return (
    <header className={classes.headerContainer}>
      <div className={classes.imgContainer}>
        <a
          href="https://pensions.gov.scot/"
          target="_blank"
        >
          <img
            src={sppaLogo}
            className={classes.img}
            alt="Scottish Public Pensions Agency Logo"
          />
        </a>
      </div>
      <div className={classes.mainHeader}>
        <h1 className={classes.pageTitle}>Remedy Benefits Illustrator</h1>
        <NavBar />
      </div>
    </header>
  );
};

export default Header;
