import React, { Fragment } from "react";
import params from "../../global_parameters";

const FrontPageQuestionSelectorText = [
  {
    id: "FAQs-Q1",
    question: "What does this illustrator do?",
    answer: (
      <p>
        The Remedy Benefits Illustrator provides an illustration of the
        projected basic service pension and lump sum amounts for your legacy
        scheme and the 2015 Section.
      </p>
    ),
  },
  {
    id: "FAQs-Q2",
    question: "Where will I find my information to complete the illustrator?",
    answer: (
      <Fragment>
        <p>
          It will be really handy if you have a copy of your latest annual
          benefit statement available. By clicking{" "}
          <a
            href="https://memberportal.pensions.gov.scot/home/"
            target="_blank"
          >
            this link
          </a>{" "}
          you can log into the Member Self Service Portal and see it online. Information on
          your payslip may also assist. In most cases an estimate is fine if not
          available.
        </p>
        <p>
          We’ll ask you for information including: if you have already left
          the scheme, your date of birth, the date you joined the scheme, your
          current pensionable pay and your pensionable pay at{" "}
          {params.remedy_start} (the annual amount before tax), your part-time
          work history, your rank, any transferred in service and any Scheme Pays debits.
        </p>
      </Fragment>
    ),
  },
  {
    id: "FAQs-Q4",
    question: "What are the legacy schemes?",
    answer: (
      <p>
        The legacy schemes refer to the 1987 Scheme and 2006 Scheme. 
        Officers who started their police service before 6 April 2006 will 
        have joined the Police Pension Scheme 1987 (1987 Scheme) unless they 
        opted not to do so. The New Police Pension Scheme 2006 (2006 Scheme) 
        came into effect for new entrants from 6 April 2006.
      </p>
    ),
  },
{
  id: "FAQs-Q7",
  question: "Who is affected by the McCloud / Sargeant judgement?",
  answer: (
    <p>
      Members of the main public service pension schemes who were in service on 
      or before 31 March 2012 and on or after 1 April 2015, including those 
      with a qualifying break in service of less than 5 years.
    </p>
  ),
},
{
  id: "FAQs-Q8",
  question: "How are members affected?",
  answer: (
    <p>
      To address the discrimination, eligible members will remain in, or be returned to, 
      their old (legacy) schemes for service between 2015 and 2022. When benefits become 
      payable members will receive a choice of whether they would rather receive the 
      benefits of their reformed pension scheme for that period.
    </p>
  ),
},
{
  id: "FAQs-Q9",
  question: "What happens to service after 2022?",
  answer: (
    <p>
      From 1 April 2022, all public servants who continue in service will only be 
      able to do so as members of their reformed 2015 pension scheme, regardless of 
      their age, therefore providing equality of treatment.
    </p>
  ),
},
{
  id: "FAQs-Q10",
  question: "Why are members who joined after 31 March 2012 not in scope?",
  answer: (
    <p>
      The Court of Appeal's ruling identified unlawful discrimination between older 
      and younger members in service on 31 March 2012. It is therefore these groups 
      that the government are now equalising treatment between.
    </p>
  ),
},
{
  id: "FAQs-Q11",
  question: "Why are these changes necessary?",
  answer: (
    <p>
      The government is committed to removing the discrimination for all those 
      affected. Not all members are better off in the legacy schemes, so it is 
      important that individuals can choose which scheme benefits they want to receive.
    </p>
  ),
},
{
  id: "FAQs-Q12",
  question: "What do I need to do?",
  answer: (
    <p>
      Eligible members do not have to do anything at this stage - they will be 
      contacted by their pension scheme to notify them of the changes. In due 
      course members will remain in, or be returned to, the old (legacy) schemes 
      for service between 2015 and 2022.
    </p>
  ),
},
{
  id: "FAQs-Q13",
  question: "Will those protected by transitional protection also receive a choice between the new and old schemes?",
  answer: (
    <p>
      Yes, members who originally received transitional protection will also 
      be provided with a choice of which scheme benefits they would prefer to receive 
      for the remedy period.
    </p>
  ),
},
];

export default FrontPageQuestionSelectorText;
