import React, { Fragment } from "react";
import classes from './5-SimpleResults.module.css'
import { useNavigate } from "react-router-dom";
import ResultsTable from "../components/simple_results/ResultsTable";

const SimpleResults = (props) => {


  let navigate = useNavigate();

  return (
    <Fragment>
      
      
          <ResultsTable></ResultsTable>
          <div className={classes.buttons_container}> 
        <p> 
          On the next page, you can see your options in more detail and check how different decisions, like changing your retirement age or lump sum amount, can affect your pension benefits. 
        </p>
          <button
            onClick ={ () => {navigate('/show-detail')} }
          > Show me the detail
          </button>

      </div>
    </Fragment>
  );
};

export default SimpleResults