import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import classes from "./TermsModal.module.css";

const Backdrop = () => {
  return <div className={classes.background} />;
};

const ModalOverlay = (props) => {
  const [tcsAgreed, setTcsAgreed] = useState(false);



  return (
    <div className={classes.modal}>
      <h2>Before you start</h2>
      <p>
      For the best experience, it’s best if you use this tool on a laptop or desktop computer.
      </p>
      <p> 
      Have your most recent Annual Benefit Statement to hand if you can. This will help to make sure your estimate as accurate as it can be, and it helps us to make sure as many members as possible can use this tool. 
      </p>
      <p>
      If you require help with filling in the "About You” section, please refer to your most recent annual benefit statement. 
      If you do not have access to this then please register for the Member Self Service portal where this information is available. 
      You can do this at: <a href="https://memberportal.pensions.gov.scot/home/">https://memberportal.pensions.gov.scot/home/</a>. 
      If you can’t access your Annual Benefit Statement, you can still use this tool to give you a general picture, 
      but bear in mind the figures will be less accurate. 
      </p>
      <button
        className={`${classes.btn_main} ${
         classes.btn_agreed
        }`}
        onClick={props.onClick}
      >
        Continue to the Benefits Illustrator
      </button>
    </div>
  );
};

const TermsModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay onClick={props.onClick} />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default TermsModal;
