// setting up age retirement options
export const generateRetirementAgeOptions = (min, max) => {
const options = [];
let isFirstNonInteger = !Number.isInteger(min);

for (let age = min; age <= max; age++) {
    if (Number.isInteger(age)) {
    options.push({ label: `${age} years old`, value: age });
    } else if (isFirstNonInteger) {
    options.push({ label: 'Immediate retirement', value: age });
    isFirstNonInteger = false;
    age = Math.ceil(age) - 1; // Adjust for the next iteration
    } else {
    options.push({ label: Math.ceil(age), value: Math.ceil(age) });
    age = Math.ceil(age) - 1; // Adjust for the next iteration
    }
}

return options;
};