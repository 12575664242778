import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import PensionBarChart from "../components/results/charts/PensionBarChart";
import LumpSumBarChart from "../components/results/charts/LumpSumBarChart";
import RetirementAgeWarning from "../components/results/RetirementAgeWarning";
import RetirementAgeSwitch from "../components/results/switches/RetirementAgeSwitch";
import InputReminder from "../components/results/switches/InputReminder";
import InflationSwitch from "../components/results/switches/InflationSwitch";
import LumpSumSwitch from "../components/results/switches/LumpSumSwitch";
import SalarySlider from "../components/results/switches/SalarySlider";
import ComparisonTable from "../components/results/ComparisonTable";
import DownloadPdfButton from "../components/results/download/DownloadButton";
import ShowAs from "../components/results/switches/ShowAs";
import TableMainResults from "../components/results/charts/TableMainResults";


import { OutputDataContext } from "../App";
import { InputDataContext } from "../shared/contexts/InputDataContext";

import classes from "./3-ShowDetail.module.css";

function ShowDetail() {
  const { inputsRef} = useContext(InputDataContext);
  const { outputDataRef } = useContext(OutputDataContext);
  const [outputData, setOutputData] = useState(outputDataRef.current)
  const [as_chart, set_as_chart] = useState(true)
  const [show_options, set_show_options] = useState(false)
  const saved_scenarios_ref = useRef({})
  

  const userExactAge = outputDataRef.current['exact_age']
  const userExactAgeRoundedDown = Math.floor(userExactAge)
  const userRetirementAge = inputsRef.current['retirementAge']
  const legacySchemeName = inputsRef.current.legacyScheme

  const [carePaymentLater, setCarePaymentLater] = useState()
  const roundedRetirementAge = Number.isInteger(userRetirementAge.value) ? userRetirementAge.value : Number(userRetirementAge.value.toFixed(2));

  // console.log("output data", outputData);

  const remedySchemeName = '2015'

  useEffect(() => {
    // Set the body margin to 0px when the ResultsPage component mounts
    document.body.style.width = '90%';

    // Cleanup function to reset the body margin when the component unmounts or user navigates away
    return () => {
      document.body.style.width = '75%'; // Reset to the default margin or desired value
    };
  }, []);

let showInflationSwitch = true

  /* really, it should not be possible for the retirement age to be below the exact age; in theory we should be able to
use `==` in the condition below instead of `<=`. However, to account for the possibility of the exact age being reduced
by a tiny amount (e.g. due to the way python treats floats), I have used <= instead. */
  // for police/fire inflation is applied to "later" CARE pension if the user is retiring before 55, so there is still inflation even in
  // the immediate retirement scenario.
  if ((userRetirementAge.value <= userExactAge) && (userRetirementAge.value >= 55)) {
    showInflationSwitch = false;
  }


  useEffect(
    () => outputDataRef.current = outputData, [outputData]
  )

  useEffect(() => {
    // just basing this on "choose legacy" because the "later pension" will be same for both options
    // if it's non-zero, then backend has determined that the member is retiring before care NPA
    if(outputData.pension.choose_legacy.new_later > 0) {
      setCarePaymentLater(true);
    } else {
      setCarePaymentLater(false);
    }
  }, 
  [outputData.pension])

  return (
    <div id="Results_Page" className = {classes.results}
    >

      <h2>A detailed view of your pension benefits options</h2>                

        <h3 style = {{'fontSize' : '1.1rem'}}> Use these tools to help you understand how different choices affect your benefits overall</h3>
        <div className = {classes.ret_switch_and_warning_container}> 
        <ShowAs 
        as_chart = {as_chart} set_as_chart = {set_as_chart}
        set_show_options = {set_show_options}
        />
        <RetirementAgeSwitch
          setOutputData = {setOutputData}
          inputsRef = {inputsRef}
          userExactAgeRoundedDown = {userExactAgeRoundedDown}
          userExactAge = {userExactAge}
          retirementAge = {inputsRef.current.retirementAge}
          legacy_npa = {outputDataRef.current.legacy_npa}
          reformed_npa = {outputDataRef.current.reformed_npa}
        />
        <RetirementAgeWarning
          legacyNPA = {outputDataRef.current.legacy_npa}
          reformedNPA = {outputDataRef.current.reformed_npa}
          retirementAge = {inputsRef.current.retirementAge.value}
          roundedRetirementAge = {roundedRetirementAge}
          legacySchemeName = {legacySchemeName}
          unauthorised_payment_charge_choose_care = {outputData.unauthorised_payment_charge.choose_care}
          unauthorised_payment_charge_choose_legacy = {outputData.unauthorised_payment_charge.choose_legacy}
          carePaymentLater={carePaymentLater}
        />
      </div>

      <div className={classes.switch_container}
          style = {
            show_options ? {} : {
              'margin' : 0, 
              'opacity' : 0,
              'maxHeight' : 0, 
              'pointerEvents' : 'none'
            }
          }
          >
            
              <InflationSwitch 
              visibility = {showInflationSwitch}
              setOutputData = {setOutputData}
              inputsRef = {inputsRef}
              />
            
            

              <LumpSumSwitch
                setOutputData = {setOutputData}
                inputsRef = {inputsRef}
                equalised_commutation_rate = {outputDataRef.current.equalised_commutation_rate}
              />


            
            <SalarySlider

              legacySchemeName={legacySchemeName}
              setOutputData = {setOutputData}
              inputsRef = {inputsRef}
            />
          </div>

          <div className = {classes.slider_container}>
            <div className = {classes.slider}
            style = {{
              'transform' : 'translateX(' + (as_chart  ? '0' : '-50')+ '%)'
            }}
            > 
              <div className={classes.chart_container}>
                <div className={classes.chart} id = {'pension_chart'}>
                  <PensionBarChart
                    key={2}
                    dummy_data = {outputData['pension']}
                    retirementAge={roundedRetirementAge}
                    legacySchemeName={legacySchemeName}
                    remedySchemeName={remedySchemeName}
                    adjustInflationState={inputsRef.current.inflation}
                    carePaymentLater={carePaymentLater}
                  />
                </div>

                <div className={classes.chart} id = {'lumpsum_chart'}>
                  <LumpSumBarChart
                    key={2}
                    dummy_data = {outputData['lump sum']}
                    retirementAge={roundedRetirementAge}
                    legacySchemeName={legacySchemeName}
                    remedySchemeName={remedySchemeName}
                    adjustInflationState={inputsRef.current.inflation}
                    carePaymentLater={carePaymentLater}
                  />
                </div>

              </div>
              <div className = {classes.table_container}> 
              <TableMainResults
              legacySchemeName={legacySchemeName}
              remedySchemeName={remedySchemeName}
              pension = {outputData.pension}
              lump_sum = {outputData['lump sum']}
              carePaymentLater={carePaymentLater}
              /> 
              </div>
            </div>
          </div>
      
      <ComparisonTable
        legacySchemeName={legacySchemeName}
        remedySchemeName={remedySchemeName}
        adjustInflation={inputsRef.current.inflation}
        pension = {outputData['pension']}
        lump_sum = {outputData['lump sum']}
        outputData = {outputData}
        saved_scenarios_ref = {saved_scenarios_ref}
        carePaymentLater={carePaymentLater}
        retirementAge={roundedRetirementAge}
      />

    <DownloadPdfButton
    legacySchemeName={legacySchemeName}
    remedySchemeName={remedySchemeName}
    adjustInflation={inputsRef.current.inflation}
    pension = {outputData['pension']}
    lump_sum = {outputData['lump sum']}
    outputData = {outputData}
    saved_scenarios_ref = {saved_scenarios_ref}
    inputsRef = {inputsRef}
    carePaymentLater = {carePaymentLater}
    roundedRetirementAge = {roundedRetirementAge}
    />
    </div>
  );
}

export default ShowDetail;
