import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import params from "../../global_parameters";

export const TCText = [
  {
    id: "T&Cs",
    text: (
      <Fragment>
        <h1>Terms and Conditions</h1>
        <p>
          By using the illustrator, you agree you have read and understood the
          Terms & Conditions set out below.
        </p>
        <h2>1. Use of this illustrator</h2>
        <p>
          This calculator is solely for guidance purposes and provides estimates only. 
          It is not intended to provide you with financial advice. If you require financial 
          advice, you should contact an independent financial adviser. GAD do not accept 
          any liability for individual financial decisions taken based on the information 
          provided in this calculator.
        </p>
        <p>
          The results shown are an indication of the benefits you may receive;
          they are not guaranteed and you should not enter into financial
          commitments on the basis of the information provided.
        </p>
        <p>
          This modeller does not override the scheme rules or pension legislation. 
          If there is a difference between the modeller and the scheme rules, 
          the rules will apply.
        </p>
        <p>
          This calculator only applies to the scheme in Scotland.
        </p>
        <h2>2. How your pension and lump sum have been estimated</h2>
        <h3>
          2.1. Deferred choice
        </h3>
        <p>
          The results section shows a comparison of pension and lump sum benefits that 
          might be received if you choose to receive legacy or reformed scheme benefits 
          for the period 1 April 2015 to 31 March 2022 (the “remedy period”). Choosing 
          to receive legacy scheme benefits for service in the remedy period may increase 
          member contributions over this period. Member contributions have not been 
          included in the illustrations.
        </p>
        <h3>2.2. Assumptions</h3>
        <p>
          The results pension and lump sum estimates are based on the following
          key assumptions:
        </p>
        <ul>
          <li>CPI inflation = 2% pa</li>
          <li>
            Pension increases = 2% pa
          </li>
          <li>
            Revaluation of 2015 Scheme CARE benefits = 3.25% pa 
          </li>
          <li>Future salary increases = 3.25% pa by default
            (salary increases can be specified by the user in the “Detailed results” section).</li>
          <li>
            Past salary increases will differ depending on whether you input your 
            current salary and/or your salary as at 1 April 2015.
          </li>
          <li>
            If both salary items are provided then your salary is assumed to 
            increase on a straight-line basis from 1 April 2015 to the present day.
          </li>
          <li>
            If only one salary item is provided then your past salary is assumed to 
            increase by 3.25% pa by default, or as specified by 
            the user in the slider in the “Detailed results” section.
          </li>
          <li>
            Salary increases do not allow for any future promotional salary increases you may receive.
          </li>
          <li>
            It is assumed that you will retire on normal terms (i.e. you are not retiring in ill-health).
          </li>
        </ul>
        <h3>2.3. Adjustments for early payment of pension</h3>
        <p>The amount of pension you may receive depends on when you retire.</p>
        <p>
          If you are a 1987 scheme member, you may be entitled to retire before age 55. 
          If you choose to retire before age 55 your 2015 Scheme pension will be 
          payable without reduction from State Pension Age (SPA). If you choose to 
          take your 2015 Scheme pension before SPA it will be actuarially reduced 
          for the earlier payment age. The default option in the calculator 
          is to take 2015 Scheme pension at age 55 with actuarial reduction. You 
          have the option to see how your 2015 pension changes if you take it later than age 55.
        </p>
        <p>
          If you choose to retire from the 1987 Scheme at age 55 or later then you will be 
          eligible to retire from the 2015 Scheme at the same time. Where retirement age 
          is not age 60, the amount of 2015 scheme pension shown includes actuarial adjustments 
          for the earlier start of payments than the 2015 scheme normal retirement age of 60.
        </p>
        <p>
          If you are a 2006 scheme member, you can retire from age 55 or later. You 
          will be eligible to retire from the 2015 Scheme at the same time. 
          Where retirement age is not age 60, the amount of 2015 scheme pension shown 
          includes actuarial adjustments for the earlier start of payments 
          than the 2015 scheme normal retirement age of 60.
        </p>
        <p>
          The actuarial adjustments described above are those currently in effect. 
          However, they will be reviewed periodically, and may increase or decrease. 
          Your benefits at retirement will depend on actuarial adjustment factors 
          in effect at that time.
        </p>
        <h3>2.4. Cash lump sum</h3>
        <p>
          At retirement you will be able to choose how much pension to commute for 
          a lump sum (within limits) from each of the 1987 and 2015 schemes. 
          This calculator uses the commutation factors currently in effect. 
          Please note that the 1987 commutation factors for Scotland are 
          underpinned by the England & Wales commutation factors. As the 
          commutation factors are higher for England & Wales currently, these 
          are used for the calculations of the lump sum in the 1987 scheme. 
          However, commutation factors are reviewed periodically and the amount 
          of cash lump sum available at your actual retirement will depend on 
          the commutation factors in effect at that time.
        </p>
        <p>
          At retirement from the 2006 scheme, you will get an automatic lump sum 
          of four times your 2006 pension. You are eligible to exchange some of 
          your lump sum for additional pension, but this is not modelled in the 
          calculator. This calculator only shows your automatic lump sum.
        </p>
        <h3>Other pension benefits</h3>
        <p>
          The results do not include any money purchase Additional Voluntary Contributions 
          (AVCs) benefits, added years or added pension which you may have purchased, pension 
          debits or other special arrangements within the schemes.
        </p>
        <p>
          The calculator does not show pension from other sources, for example the 
          state pension or other private arrangements you may have.
        </p>
        <p>
          Your scheme provides survivor benefits payable in the event of your death, 
          which are not shown here. See your scheme guide for details. 
          Your benefit statement may also provide further information.
        </p>
        <p>
          No allowance has been made for guaranteed minimum pension (GMP) and any 
          impact that GMP has on when benefits can be taken.
        </p>
        <h3>Part-time working</h3>
        <p>
          If you indicated you have worked part time or plan to do so in the future, the 
          calculator will allow for part-time hours worked.
        </p>
        <h3>Service cap</h3>
        <p>
          Members reaching the service cap (30 years in the 1987 scheme and 35 years 
          in the 2006 scheme) have been assumed to be allowed to join the 2015 scheme 
          from 1 April 2022. Members are able to opt out of the scheme on reaching 30 
          years of service (whilst remaining in employment), with entitlement to 
          immediate payment of their pension on subsequent retirement. The calculator 
          shows the benefits payable from age 60 only. Please seek information from 
          the scheme administrator if you are considering an alternative retirement date.
        </p>
        <h2>3. Taxation</h2>
        <p>
          The illustrations do not allow for taxation. Your benefits will be subject to 
          the various tax rates and limits in force. You may wish to seek specialist 
          advice if you think you could be affected.
        </p>
        <p>
          In certain circumstances, for some members, it is currently possible to 
          take a maximum commuted lump sum from the 1987 scheme which exceeds that 
          which HMRC allows to be paid tax free. In those circumstances if maximum 
          commutation is taken the member will incur a tax liability. The maximum 
          lump sum figures given in the results are not limited to what can be taken 
          tax free. The results page will show the unauthorised payment charge as a 
          monetary value, but will not show the maximum commutation figure net of tax.
        </p>
      </Fragment>
    ),
  },
];

export const SmallPrintText = [
  {
    id: "SP",
    text: (
      <Fragment>
        <h2>How to use this calculator</h2>
        <p>
        It will not check your information against pension or other systems,
        so if you enter inaccurate information, the figures you get will be 
        of limited value. No data you enter into this calculator is stored 
        or processed.
        </p>
        <p>
          By using this calculator, you agree that you have read
          and understood the Terms & Conditions as set out in the{" "}
          <Link to="/T&Cs">T&Cs' tab</Link>.
        </p>
        <p>
          This calculator is for guidance and illustration - 
          it will give you a basic illustration of your pension benefits 
          in the 2015 Section and your legacy scheme in two scenarios: 
          choosing the legacy scheme and choosing the reformed scheme. 
          Things such as tax debits are not taken into account. If you have these, 
          or other additional options, the figures you get may be less accurate, 
          but still helpful. The outputs of the calculator are not financial 
          advice - if you’d like to speak to an independent financial adviser 
          you can find one at <a href="https://www.unbiased.co.uk/">unbiased.co.uk</a>.
        </p>
        <p>The assumptions we have used are:</p>
        <table>
          <tbody>
            <tr>
              <td>Consumer Price Index</td>
              <td>2% per year</td>
            </tr>
            <tr>
              <td>Earnings inflation</td>
              <td>3.25% per year</td>
            </tr>
          </tbody>
        </table>
        <p>
        The figures output by the calculator are projected benefits and will not
         be the same as those in your Annual Benefit Statement.
        </p>
        <p>
        The projected benefits in this calculator are in “today's money” – 
        this means the values are adjusted for inflation and represent what 
        the pension would be worth today, so you can compare it with your 
        current earnings and the amount you think you’ll need in retirement.
        </p>
      </Fragment>
    ),
  },
];

export const IntroText = [
  {
    id: "IT",
    text: (
      <Fragment>
        <h2>Welcome to the Remedy Benefits Illustrator</h2>
        <p>
          The Remedy gives you a choice, to be made at the point when you take
          your benefits. That choice is between your legacy scheme 
          and the reformed scheme for the Remedy period (1 April 2015 to 31
          March 2022). Both schemes offer highly valuable benefits.
        </p>
        <p>
          The illustrator is designed to provide members who are affected by the
          Remedy with an overview of how different choices may impact their
          benefits at retirement. It allows you to make an approximate
          comparison based on your inputs and certain assumptions about what
          happens in the future. It does not allow for all circumstances.
        </p>
      </Fragment>
    ),
  },
];
