import React, { useState, Fragment, useEffect } from "react";
import { currencyFormatter, sumValues } from "../../shared/utilities/Formatters";
import classes from "./ComparisonTable.module.css";
import params from "../../shared/utilities/global_parameters";
import WarningIcon from "../../assets/images/warning.png"

const ComparisonTable = (props) => {
  const equalise = props.outputData.equalise
  //capitalise first letter
  function cfl(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  const inflation_relevant = (props.outputData.exact_age < props.outputData.retirementAge) || (props.outputData.exact_age >= props.outputData.retirementAge && props.outputData.retirementAge < 55);



 
  const renderExtraRows = (x, index) => {
    // Destructure the 'classic' and 'alpha' objects from x
    const { classic, alpha } = x;
  
    // Function to generate a table row from an object
    const generateRow = (data, include_span) => {
      return (
        <tr className = {classes.extra_row}>
          {Object.keys(data).map((key) => {
            if((key === 'Lump Sum') && include_span) {
              return(
              <td key={key} className = {classes.lump_sum_td}>
                {data[key]} 
                <button
                onClick = {() => {
                  
                  var prev = saved_scenarios
                  var prev = prev.filter((value, i) => i !== index )
                  set_saved_scenarios(prev)

                }}
                > delete</button>
                </td>
              )
            } else {
            return(<td key={key}>{data[key]}</td>)
            }
        })}
        </tr>
      );
    };
  
    return (
      <Fragment>
        {generateRow(classic, true)}
        {generateRow(alpha, false)}
      </Fragment>
    );
  };

  const [saved_scenarios, set_saved_scenarios] = useState([])

  useEffect(
    () => {props.saved_scenarios_ref.current = saved_scenarios}, 
    [saved_scenarios]
  )

  const push_saved_scenario = () => {
    var prev = [...saved_scenarios]
    prev.push(
      {'classic' : {
        'Retirement Age' : props.retirementAge, 
        'Salary Increase' : props.outputData.salaryIncrease + '%', 
        'Inflation' : inflation_relevant ? cfl(props.outputData.inflation) : 'NA', 
        '2015 Commutation' : equalise === 'equal' ? 'Equalised' : props.outputData.commutation + '%',
        ...(props.legacySchemeName === "1987" && {
          [props.legacySchemeName + ' Commutation'] : props.outputData.commutation1987 + "%"
        }),
        'Scheme' : 'Legacy',
        [props.legacySchemeName + ' pension'] : currencyFormatter(props.pension.choose_legacy.old), 
        [props.remedySchemeName + ' pension'] :  props.carePaymentLater ? currencyFormatter(props.pension.choose_legacy.new_later) : currencyFormatter(props.pension.choose_legacy.new), 
        'Total Pension' : currencyFormatter(
          sumValues([
            props.pension.choose_legacy.old,
            props.pension.choose_legacy.new,
            props.pension.choose_legacy.new_later,
          ])
        ), 
        [props.legacySchemeName + ' lump sum'] : currencyFormatter(props.lump_sum.choose_legacy.old), 
        [props.remedySchemeName + ' lump sum'] : currencyFormatter(props.lump_sum.choose_legacy.new), 
        'Lump Sum' : currencyFormatter(          
          sumValues([
          props.lump_sum.choose_legacy.old,
          props.lump_sum.choose_legacy.new
          ])
        )
      },
    'alpha' : {
      'Retirement Age' : props.retirementAge, 
        'Salary Increase' : props.outputData.salaryIncrease + '%', 
        'Inflation' : inflation_relevant ? cfl(props.outputData.inflation) : 'NA', 
        '2015 Commutation' : equalise === 'equal' ? 'Equalised' : props.outputData.commutation + '%', 
        ...(props.legacySchemeName === "1987" && {
          [props.legacySchemeName + ' Commutation'] : props.outputData.commutation1987 + "%"
        }),
        'Scheme' : 'Reformed', 
        [props.legacySchemeName + ' pension'] : currencyFormatter(props.pension.choose_reformed.old), 
        [props.remedySchemeName + ' pension'] :  props.carePaymentLater ? currencyFormatter(props.pension.choose_reformed.new_later) : currencyFormatter(props.pension.choose_reformed.new), 
        'Total Pension' : currencyFormatter(
          sumValues([
            props.pension.choose_reformed.old,
            props.pension.choose_reformed.new,
            props.pension.choose_reformed.new_later,
          ])
        ), 
        [props.legacySchemeName + ' lump sum'] : currencyFormatter(props.lump_sum.choose_reformed.old), 
        [props.remedySchemeName + ' lump sum'] : currencyFormatter(props.lump_sum.choose_reformed.new), 
        'Lump Sum' : currencyFormatter(          
          sumValues([
            props.lump_sum.choose_reformed.old,
            props.lump_sum.choose_reformed.new
          ])
        )
    }}
    )
    set_saved_scenarios(prev)
  }

  return (
    <div className={classes.container} >
      <h2>Your Comparison Table</h2>
      <p>
        You can use this table to compare the expected benefits in the scenarios
        outlined above. The figures below are in {props.outputData.inflation} terms.
      </p>
      <p>
        Click 'Save' to add the current scenario to the bottom of the table for comparison. The top rows will keep updating with new changes. 
      </p>
      <p>
      {
        props.legacySchemeName === "1987" ? 
        <p className = {classes.lump_sum_warning}>
        Please note that your lump sum in this version of the calculator may be reduced if you incur an unauthorised payment tax charge. 
        If this affects you, you will see a warning in the <img src={WarningIcon} alt="warning" style ={{width: '20px', height: '18px', verticalAlign: 'middle'}}>
        </img> warnings section titled 'Lump sum tax threshold exceeded'. You can click this for more information.
        </p>
        : ""
      }
      </p>
      <table className={classes.comp_table} id = {'comparison_table'}>
      <thead>
        <tr>
          <th>Retirement Age</th>
          <th>Salary Increase</th>
          <th>Inflation</th>
          <th>2015 Commutation</th>
          {
            props.legacySchemeName === "1987" ? 
            <th>{props.legacySchemeName} Commutation</th> : ""
          }
          <th>Option</th>
          <th>{props.legacySchemeName} pension</th>
          <th>{props.remedySchemeName} pension</th>
          <th>Total Pension</th>
          <th>{props.legacySchemeName} lump sum</th>
          <th>{props.remedySchemeName} lump sum</th>
          <th>Total Lump Sum</th>
        </tr>
      </thead>
        <tbody>
          <tr>
            <td>{props.retirementAge}</td>
            <td >{props.outputData.salaryIncrease}%</td>
            <td >{inflation_relevant ? cfl(props.outputData.inflation) : 'NA'}</td>
            <td >{equalise === 'equal' ? 'Equalised' : props.outputData.commutation + '%'}</td>
            {
              props.legacySchemeName === "1987" ? 
              <td> {props.outputData.commutation1987 + "%"} </td> : ""
            }
            <td> Legacy </td>
            <td>
              {currencyFormatter(props.pension.choose_legacy.old)}
            </td>
            <td>
              {props.carePaymentLater ? currencyFormatter(props.pension.choose_legacy.new_later) : currencyFormatter(props.pension.choose_legacy.new)}
            </td>
            <td>
              {currencyFormatter(
                sumValues([
                  props.pension.choose_legacy.old,
                  props.carePaymentLater ? props.pension.choose_legacy.new_later : props.pension.choose_legacy.new,
                ])
              )}
            </td>
            <td>
              {currencyFormatter(props.lump_sum.choose_legacy.old)}
            </td>
            <td>
              {currencyFormatter(props.lump_sum.choose_legacy.new)}
            </td>
              <td className = {classes.lump_sum_td}>
                {currencyFormatter(
                  sumValues([
                    props.lump_sum.choose_legacy.old,
                    props.lump_sum.choose_legacy.new,
                  ])
                  )}
                <button
                onClick={() => {
                  push_saved_scenario()
                }
              }
                > Save </button>
              </td>
          </tr>
          <tr>
            <td>{props.retirementAge}</td>
            <td >{props.outputData.salaryIncrease}%</td>
            <td >{inflation_relevant ? cfl(props.outputData.inflation) : 'NA'}</td>
            <td >{equalise === 'equal' ? 'Equalised' : props.outputData.commutation + '%'}</td>
            {
            props.legacySchemeName === "1987" ? 
            <td> {props.outputData.commutation1987 + "%"} </td> : ""
            }
            <td> Reformed </td>
            <td>
              {currencyFormatter(props.pension.choose_reformed.old)}
            </td>
            <td>
              {props.carePaymentLater ? currencyFormatter(props.pension.choose_reformed.new_later) : currencyFormatter(props.pension.choose_reformed.new)}
            </td>
            <td>
              {currencyFormatter(
                sumValues([
                  props.pension.choose_reformed.old,
                  props.carePaymentLater ? props.pension.choose_reformed.new_later : props.pension.choose_reformed.new,
                ])
              )}
            </td>
            <td>
              {currencyFormatter(props.lump_sum.choose_reformed.old)}
            </td>
            <td>
              {currencyFormatter(props.lump_sum.choose_reformed.new)}
            </td>
              <td>
                {currencyFormatter(
                  sumValues([
                    props.lump_sum.choose_reformed.old,
                    props.lump_sum.choose_reformed.new,
                  ])
                  )}
              </td>
          </tr>
          {
            saved_scenarios.map((x, index) => {return(renderExtraRows(x, index)) })
          }
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;
