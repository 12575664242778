import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import html2canvas from 'html2canvas';
import classes from './DownloadButton.module.css'
import { currencyFormatter, sumValues } from '../../../utilities/Formatters';
import { formatDate } from '../../../utilities/Formatters';

const DownloadPdfButton = (props) => {

  const inflation_relevant = (props.outputData.exact_age < props.outputData.retirementAge) || (props.outputData.exact_age >= props.outputData.retirementAge && props.outputData.retirementAge < 55);

  //capitalise first letter
  function cfl(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // generate timestamp
  const generateTimestamp = () => {
    const now = new Date();
    const date = now.toLocaleDateString(); // "MM/DD/YYYY"
    const time = now.toLocaleTimeString(); // "HH:MM:SS AM/PM"
    return `${date} ${time}`;
  };

  console.log('saved scenarios: ', props.saved_scenarios_ref.current)
    const generatePdf = async () => {
        // Initialize jsPDF
        const doc = new jsPDF();

        // Set up timestamp 
        const timestamp = generateTimestamp();

        // Get CSS variable value from body
        const bodyStyles = getComputedStyle(document.body);
        const tableHeadColor = bodyStyles.getPropertyValue('--main-color').trim();
      
        // Add a paragraph of text at the top
        doc.setFont('helvetica','bold'); // Make the text bold
        const pageWidth = doc.internal.pageSize.getWidth();
        const introText = 'Your comparison table';
        const introTextWidth = doc.getTextWidth(introText);
        doc.text(introText, (pageWidth - introTextWidth) / 2, 10); // Centered text
        doc.setFont('helvetica','normal')

        // adding timestamp
        doc.setFontSize(9);
        doc.text(`Generated on: ${timestamp}`, 10, 20);


        // Define the columns of your table
        const tableColumn = [
            "Retirement Age",
            "Salary Increase",
            "Inflation",
            "2015 Commutation",
            ...(props.legacySchemeName === "1987" ? [props.legacySchemeName + " Commutation"] : []),
            "Option",
            props.legacySchemeName + ' pension',
            props.remedySchemeName + ' pension',
            "Total Pension",
            props.legacySchemeName + ' lump sum',
            props.remedySchemeName + ' lump sum',
            "Lump Sum",
          ];
      
          let tableRows = [];

          const baseRow = [
            props.roundedRetirementAge,
            props.outputData.salaryIncrease + "%",
            inflation_relevant ? cfl(props.outputData.inflation) : "NA",
            props.outputData.equalise === 'equal' ? 'Equalised' : props.outputData.commutation + '%',
            ...(props.legacySchemeName === "1987" ? [props.outputData.commutation1987 + "%"] : []),
            'Legacy', 
            currencyFormatter(props.pension.choose_legacy.old),
            currencyFormatter(props.carePaymentLater ? props.pension.choose_legacy.new_later: props.pension.choose_legacy.new),
            currencyFormatter(sumValues([
              props.pension.choose_legacy.old,
              props.carePaymentLater ? props.pension.choose_legacy.new_later : props.pension.choose_legacy.new
            ])),
            currencyFormatter(props.lump_sum.choose_legacy.old),
            currencyFormatter(props.lump_sum.choose_legacy.new),
            currencyFormatter(
              sumValues([
                props.lump_sum.choose_legacy.old,
                props.lump_sum.choose_legacy.new,
              ])
              ),
          ];
        
          // Push the base row into tableRows
          tableRows.push(baseRow);
        
          // Assuming you have similar data for the 'reformed' scheme
          const reformedRow = [
            props.roundedRetirementAge,
            props.outputData.salaryIncrease + "%",
            inflation_relevant ? cfl(props.outputData.inflation) : "NA",
            props.outputData.equalise === 'equal' ? 'Equalised' : props.outputData.commutation + '%',
            ...(props.legacySchemeName === "1987" ? [props.outputData.commutation1987 + "%"] : []),
            'Reformed', 
            currencyFormatter(props.pension.choose_reformed.old),
            currencyFormatter(props.carePaymentLater ? props.pension.choose_reformed.new_later : props.pension.choose_reformed.new),
            currencyFormatter(sumValues([
              props.pension.choose_reformed.old,
              props.carePaymentLater ? props.pension.choose_reformed.new_later : props.pension.choose_reformed.new,
            ])),
            currencyFormatter(props.lump_sum.choose_reformed.old),
            currencyFormatter(props.lump_sum.choose_reformed.new),
            currencyFormatter(
              sumValues([
                props.lump_sum.choose_reformed.old,
                props.lump_sum.choose_reformed.new,
              ])
              )
          ];
        
          // Push the reformed row into tableRows
          tableRows.push(reformedRow);
        console.log('ssr', props.saved_scenarios_ref.current)
          // Add rows from saved_scenarios_ref
          props.saved_scenarios_ref.current.forEach(scenario => {
            const { classic, alpha } = scenario;
        
            const classicRow = [
              classic['Retirement Age'],
              classic['Salary Increase'],
              classic['Inflation'],
              classic['2015 Commutation'],
              ...(props.legacySchemeName === "1987" ? [classic[props.legacySchemeName + " Commutation"]] : []),
              'Legacy',
              classic[props.legacySchemeName + ' pension'],
              classic[props.remedySchemeName + ' pension'],
              classic['Total Pension'],
              classic[props.legacySchemeName + ' lump sum'],
              classic[props.remedySchemeName + ' lump sum'],
              classic['Lump Sum'],
            ];
        
            const alphaRow = [
              alpha['Retirement Age'],
              alpha['Salary Increase'],
              alpha['Inflation'],
              alpha['2015 Commutation'],
              ...(props.legacySchemeName === "1987" ? [alpha[props.legacySchemeName + " Commutation"]] : []),
              'Reformed',
              alpha[props.legacySchemeName + ' pension'],
              alpha[props.remedySchemeName + ' pension'],
              alpha['Total Pension'],
              alpha[props.legacySchemeName + ' lump sum'],
              alpha[props.remedySchemeName + ' lump sum'],
              alpha['Lump Sum'],
            ];
        
            tableRows.push(classicRow, alphaRow);
          });

        const tableStartY = 40;
      
        // Add the table to the PDF
        doc.setFontSize(15);
        doc.text('Use this table to compare the expected benefits in the scenarios you have selected.', 10, tableStartY - 10);
        doc.autoTable({
          columns: tableColumn,
          body: tableRows,
          startY: tableStartY, // Start the table after the introductory text
          styles: { fontSize: 8 }, // Customize table styles
          theme: 'grid',
          headStyles: { fillColor: tableHeadColor } // using sppa colour
        });

        const other_inputs_column = [
            "Date of Birth",
            "Joining Date",
            "Rank",
            "Current Salary",
            "Salary in April 2015",
          ];
          
        const other_inputs_data = [
            formatDate(props.inputsRef.current.dateOfBirth), 
            formatDate(props.inputsRef.current.dateOfJoining),
            props.inputsRef.current.rank.value,
            currencyFormatter(props.inputsRef.current.salaryCurrent), 
            currencyFormatter(props.inputsRef.current.salary2015.value)
        ]

        doc.text('Your input data is summarised below.', 10, tableStartY + 60 + 20 * props.saved_scenarios_ref.current.length)
        doc.autoTable({
            columns: other_inputs_column,
            body: [other_inputs_data],
            startY: tableStartY + 70 + 20 * props.saved_scenarios_ref.current.length, // Start the table after the introductory text
            styles: { fontSize: 8 }, // Customize table styles
            theme: 'grid',
            headStyles: { fillColor: tableHeadColor } 
          });

        const part_time_column = [
            'From', 
            'To', 
            'Percentage'
        ]

        const part_time_data = props.inputsRef.current.partTimeService.map(
            (x) => {
                return(
                    [formatDate(x['From']), formatDate(x['To']), x['ftePercentage']+ '%']
                )
            }
        )

        if(part_time_data.length > 0) {
            doc.text('You entered the following data regarding your part-time service.', 10, tableStartY + 100 + 20 * props.saved_scenarios_ref.current.length)
            doc.autoTable({
                columns: part_time_column,
                body: part_time_data,
                startY: tableStartY + 110 + 20 * props.saved_scenarios_ref.current.length, // Start the table after the introductory text
                styles: { fontSize: 8 }, // Customize table styles
                theme: 'grid',
                headStyles: { fillColor: tableHeadColor } 
            });

        } else {
          doc.text('You have not entered any input data relating to part-time service.', 10, tableStartY + 100 + 20 * props.saved_scenarios_ref.current.length)
        }

        // Save the PDF with timestamp in filename 
        const timestampForFilename = timestamp.replace(/[\/: ]/g, '-');
        doc.save(`your-data-${timestampForFilename}.pdf`);
      };

  

  return (
    <div className = {classes.download_button_container}> 
    <button
    className = {classes.download_button}
    onClick={generatePdf}>Download PDF</button>
    </div>
  );
};

export default DownloadPdfButton;
