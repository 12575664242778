import React, { useEffect, useState } from "react";
import QuestionSelector from "../components/home/QuestionSelector";
import TextBlock from "../components/general/text/TextBlock";
import Footer from "../components/home/Footer";
import FrontPageQuestionSelectorText from "../assets/data/FrontPageQuestionSelectorText";
import { IntroText, SmallPrintText } from "../assets/data/TextBlocks";

import { useScrollPosition } from "../shared/utilities/useScrollPosition";

function HomePage() {
  const scrollPosition = useScrollPosition();
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    if (scrollPosition > 90) {
      setIsScrolledDown(true);
    } else {
      setIsScrolledDown(false);
    }
  }, [scrollPosition]);

  return (
    <div id="Home_Page">
      <TextBlock blocks={IntroText} />
      <br></br>
      <h1>Frequently asked questions</h1>
      <QuestionSelector items={FrontPageQuestionSelectorText} />
      <TextBlock blocks={SmallPrintText} />
      <Footer />
    </div>
  );
}

export default HomePage;
